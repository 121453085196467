@import '~antd/dist/antd.css';

img.responsive {
  max-width: 100%;
  max-height: 100%;
}

.inspectionListItem {
  color: #fff;
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
  padding: 10px;
  overflow: hidden;
  height: 90px;
  background-color: rgba(24, 144, 255, 0);
  transition: background-color 0.5s, opacity 0.75s;
}

.inspectionListItem:nth-child(2n + 1) {
  border-top: 1px solid #ccc2;
  border-bottom: 1px solid #ccc2;
}

.inspectionListItem--active {
  background-color: rgba(24, 144, 255, 0.1);
}

.truncated {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.inventoryItemRow {
  overflow: hidden;
  height: 95px;
  padding: 10px;
  box-sizing: border-box;
  border-bottom: 1px solid #ccc2;
  display: flex;
  background-color: rgba(24, 144, 255, 0);
  transition: border-color 0.5s, opacity 0.75s;
  opacity: 1;
  border-left: 5px solid transparent;
}

.inventoryItemRow--active {
  border-left-color: #1890ff;
}

.inventoryItemRow--fadeout {
  opacity: 0;
}

.inventoryItemThumbnail {
  display: inline-flex;
  border: 5px solid transparent;
  border-radius: 10px;
  height: 75px;
  width: 75px;
  background-color: #fff;
  justify-content: center;
  align-content: center;
  vertical-align: middle;
  align-items: center;
  box-sizing: border-box;
  flex-shrink: 0;
  cursor: pointer;
  box-shadow: 0px 0px 3px #999;
}

.inventoryItemDetailQuantity {
  text-align: left;
  font-size: 64px;
}

.inventoryItemThumbnail--large {
  height: 230px;
  width: 230px;
}

.inventoryItemThumbnail--small {
  height: 48px;
  width: 48px;
}

.inventoryItemThumbnail img {
  max-width: 55px;
  max-height: 55px;
}

.inventoryItemThumbnail--large img {
  max-width: 215px;
  max-height: 215px;
}

.inventoryItemThumbnail--small img {
  max-width: 32px;
  max-height: 32px;
}

.inventoryItemQuantity {
  display: inline-block;
  font-size: 40px;
  line-height: 55px;
  vertical-align: middle;
  width: 115px;
  flex-shrink: 0;
}

.leftRail {
  background: #fff;
  border-right: 1px solid #ccc;
  height: 100vh;
  padding: 10px;
  position: fixed;
  left: 0;
  width: 300px;
  flex: 0 0;
}

.printMissingPiecePage {
  break-inside: avoid;
}

.printSafeItem {
  margin-top: 10px;
}
.printSafeItem > div {
  display: flex;
  width: 100%;
  height: 100%;
}
.printSafeItem .ant-card {
  flex: 1 1;
}
.ant-card-body {
  padding: 2vh;
}

.printSafeItem .printSetNumber {
  position: absolute;
  z-index: 10;
  right: 0;
  transform: rotate(90deg) translate(34px);
}

@media print {
  .printSafeItem {
    break-inside: avoid;
    height: 2.7in;
    width: 4in;
  }

  .printHide,
  .ant-card-actions {
    display: none !important;
  }

  .leftRail {
    display: none !important;
    width: 0px !important;
  }
  .contentPane {
    height: auto !important;
    overflow: visible !important;
    margin-left: 0px !important;
  }
  .smallerPrintText {
    font-size: 12px;
  }
}

.sorting {
  z-index: 1001;
}

.sortHandle {
  margin-right: 1em;
}
.sortRow {
  cursor: row-resize;
}

.ant-btn-ghost {
  color: #fff;
}
